/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "hpa"
import Rails from "@rails/ujs"

// Vendor
import "jquery-ui/ui/widgets/sortable"
import "jquery.highlight"
import "jquery.spin"
import "jquery-time-duration-picker"
import "js.cookie"
import "bootstrap"
import "spin"
import "select2"
import "foundation-sites"

// Old App Assets
import "accordion"
import "article-search"
import "article_link"
import "close-button"
import "dismiss-alerts"
import "mobile_menu"
import "navigation"
import "notifications"
import "out_board"
import "page-search"
import "search-bar"
import "search"
import "staff_directory"
import "staff_search"
import "taggable"
import "admin/nested_files"
import "admin/news_items"
import "admin/shared"
import "admin/sortable"
import "admin/users"
import "views/news-search"

Rails.start()

global.Cookies = require('js.cookie');

window.$ = window.jQuery = require("jquery");

$(function(){
  $(document).foundation();
  NewsSearchView.init($(".news-items.index"));
});
