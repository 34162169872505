$(document).ready(function(){
  HPA.accordion();
});

HPA.accordion = function() {
  if ($(".pages.index").length) {
    $(".js-our-world-accordion").on("click", "[data-accordion-item]", function() {
      $tab = $(this);
      $currentAccordion = $tab.parentsUntil(".accordion__column")
      $(".js-our-world-accordion").each(function() {
        $accordion = $(this);
        if (!$accordion.is($currentAccordion)) {
          $accordion.children(".is-active").children(".accordion__content").each(function() {
            $accordion.foundation("up", $(this));
          });
        }
      });
      HPA.resetTopButton();
    });
  }
}