$(function() {
  $("#search-input").focus();
});

HPA.applySearchTagFilter = function(tag) {
  $(".search-bar__button--tag.active").each(function(){
    $(this).removeClass("active");
  });
  $(".search-bar__button--tag[data-tag='" + tag + "']").each(function(){
    $(this).addClass("active");
  });
  $("#search-select").val(tag).trigger("change");
}

HPA.clearSearchTagFilter = function() {
  $("#search-select").val(null).trigger("change");
  $(".search-bar__button--tag.active").each(function(){
    $(this).removeClass("active");
  });
}

HPA.clearSearchKeyword = function() {
  $("#search-input").val(null);
  HPA.hideNoResultsFound();
}

HPA.showNoResultsFound = function() {
  if($("#js-search-results").children(':visible').length < 1) {
    $("#js-no-result").show()
  }
}

HPA.hideNoResultsFound = function() {
  if($("#js-no-result:visible").length) {
    $("#js-no-result").hide()
  }
}

var timeout = null;
/* wait until the user has stopped typing for 250ms */
HPA.delay = (function(fn, time){
  clearTimeout(timeout);
  // Make a new timeout set to go off in 800ms
  timeout = setTimeout(fn, time);
});

HPA.highlight = (function(keyword) {
  $("#js-search-results").unhighlight();
  $("#js-search-results").highlight(keyword);
});

HPA.getUrlParameter = (function(url, name){
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(url);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
});