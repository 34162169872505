$(function(){
  if($(".administration").length == 0 && $(".authentication").length == 0) {
    $notifications = $(".js-notification");
    $notificationContainer = $("#js-notifications");

    HPA.displayNotifications($notificationContainer, $notifications);

    HPA.notificationsAutoRefresh();

    $(".js-notification").on("close.zf.trigger", function() {
      $(this).slideUp(500, function() {
        var id = $(this).data("id").toString();
        HPA.permanentlyDismissNotification(id);
        if($(".js-notification:visible").length == 0) {
          $notificationContainer.slideUp(1000);
          $notificationContainer.removeClass("is-open");
        }
      });
    });
  }
});

HPA.notificationsAutoRefresh = function() {
  var interval = 10000;
  setInterval(function() {
    var now = new Date();
    var time_since = new Date(now.getTime() - interval);
    $.get("/notifications/", { published_at: time_since }, null, "script");
  }, interval);
};

HPA.displayNotifications = function(container, notifications) {
  var dismissedNotifications = HPA.dismissedNotifications();
  var numVisibleNotifications = 0;
  notifications.each(function() {
    if(dismissedNotifications.indexOf($(this).data("id").toString()) == -1) {
      $(this).show();
      numVisibleNotifications++;
    }
  });
  if(numVisibleNotifications > 0) {
    container.slideDown(1000);
    container.addClass("is-open");
  }
};

HPA.dismissedNotifications = function() {
  var cookieValue = Cookies.get("hpa-notifications");
  return cookieValue ? cookieValue.split("|") : [];
};

HPA.permanentlyDismissNotification = function(notificationId) {
  var dismissedNotifications = HPA.dismissedNotifications();
  if(dismissedNotifications.indexOf(notificationId) == -1) {
    dismissedNotifications.push(notificationId);
    Cookies.set("hpa-notifications", dismissedNotifications.join("|"), { expires: 365 });
  }
};
