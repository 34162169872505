$(function(){
  /* toggle the visibility of the search component */
  $(document)
    .on("on.zf.toggler", "#js-search,#js-tag-cloud", function(e) {
      $(e.target).slideDown(500);
      $("#search-input").focus();
    })
    .on("off.zf.toggler", function(e) {
      $(e.target).slideUp(500);
    });

  /* tag cloud dropdown arrow */
  $(".search-bar__link").click(function(){
    $(this).find(".fa-sort-down").toggleClass("up"); 
  });

  /* select2 window resize bugfix */
  $(window)
    .on("changed.zf.mediaquery", function(event, newSize, oldSize) {
      if(oldSize == "small" && $(".select2-container--hpa-light.select2-container--open").length > 0) {
        $(".select2-hidden-accessible").select2("close");
      }
    });
});