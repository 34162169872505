
$(function(){
  if($(".pages.index").length) {

    $searchContainer = $("#js-search-results");

    $("#js-search-submit").click(function() {
      HPA.searchPages();
    });

    /* search as you type */
    $("#search-input").keypress(function (e) {
      var key = e.which;
      if(key == 13) {
        HPA.searchPages();
        return false;
      }
    });

    $("#search-input").on("keyup", function() {
      query = $(this).val();
      if(query.length >= 3 || query.length == 0) {
        HPA.delay(function(){
          HPA.searchPages();
          return false;
        }, 250 );
      }
    });

    $("#js-search-clear").click(function() {
      HPA.clearSearchKeyword();
      HPA.searchPages();
      $searchContainer.removeClass("searching");
    });
  }
});

HPA.searchPages = function() {
  $searchContainer = $("#js-search-results");
  $searchContainer.css("opacity", 0)
  $searchContainer.addClass("searching");
  var keyword = $("#search-input").val();
  $.get("/our-world/", { q: keyword }, function() {
    HPA.resetTopButton();
    HPA.highlight(keyword);
    $searchContainer.css("opacity", 1);
    $searchContainer.foundation();
  }, "script");
};

