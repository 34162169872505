// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/


(function() {
  $(function() {
    return $('.checkall').click(function() {
      return $(this).parents('fieldset:eq(0)').find(':checkbox').prop('checked', this.checked);
    });
  });

}).call(this);