$(function(){
  /* news item article page only */
  if($(".news-items.show").length) {
    /* select2 mobile category dropdown */
    $searchSelect = $("#search-select").select2({
      placeholder: "Search news by category...",   
      theme: "hpa-light",
      minimumResultsForSearch: Infinity,
      allowClear: true
    });

    $("#js-search-clear").click(function() {
      HPA.clearSearchTagFilter();
      HPA.clearSearchKeyword();
    }); 

    /* selection changed in category pills */
    $(".search-bar__cloud").on("click", ".search-bar__button--tag", function(e) {
      e.preventDefault();

      /* unselect a filter */
      if($(this).hasClass("active")) {
        HPA.clearSearchTagFilter();
      }
      /* select a new filter and clear existing ones */
      else {
        HPA.applySearchTagFilter($(this).data("tag"));
      }
    });
  }
});