// Expand/shrink pages view
//
$(function(){
  $(document).on('click', '.expander', function(){
    $("."+$(this).attr("id")).show();
    $(".shrinker_"+$(this).attr("id")).show();
    $(".expander_"+$(this).attr("id")).hide();
    return false;
  });

  $(document).on('click', '.shrinker', function(){
    shrink($(this))
    return false;
  });

  $(".child").hide();
  $(".grandchild").hide();
  $(".shrinker").hide();

  $(function() {
    // Only execute on the administrators pages index page
    if($("#pages-list").length) {
      $( ".parent_page" ).sortable({
        handle:'.handle',
        update:function() { update_sortable($(this)); }
      });

      $(".parent_page").bind("mousedown", function(e) {
          e.stopPropagation(); // fixes crazy IE behaviour
        });
      $( ".children_page" ).sortable({
        handle:'.handle',
        connectWith: '.children_page, .grandchildren_page',
        update:function() { update_sortable($(this)); },
        receive: function (event, ui) { changeParent($(this), ui); }
      });

      $(".children_page").bind("mousedown", function(e) {
          e.stopPropagation(); // fixes crazy IE behaviour
        });
      $( ".grandchildren_page" ).sortable({
          handle:'.handle',
          connectWith: '.grandchildren_page, .children_page', // joins together all the items so the can be dragged from one parent to another. (http://api.jqueryui.com/sortable/#option-connectWith)
          update:function() { update_sortable($(this)); }, // when items in the same list are reordered. (http://api.jqueryui.com/sortable/#option-update)
          receive: function (event, ui) { changeParent($(this), ui); } // when an item is dropped on a list that is from another different list (ie changing parent). (http://api.jqueryui.com/sortable/#option-recevie)
        });

      $(".grandchildren_page").bind("mousedown", function(e) {
          e.stopPropagation(); // fixes crazy IE behaviour
        });

      $( ".categories-sortable" ).sortable({
          handle:'.handle',
          update:function() { $.post('categories/position', '_method=put&authenticity_token=' + encodeURIComponent(AUTH_TOKEN)+'&'+$(this).sortable('serialize')); }
        });

      $(".categories-sortable").bind("mousedown", function(e) {
        e.stopPropagation(); // fixes crazy IE behaviour
      });
    }
  });
});

function changeParent($this, ui) {
  ui.item.removeClass('child').removeClass('grandchild').addClass(ui.item.parents('ul').first().attr('data-page-type'))
  var parentId = ui.item.parents('li.page_row').first().attr('id').replace('page_', '')
  var childItemId = ui.item.attr('id').replace('page_', '')
  $.post('pages/' + childItemId + '/change_parent', '_method=put&authenticity_token=' + encodeURIComponent(AUTH_TOKEN)+'&parent_id='+ parentId).done(function() { update_sortable($this); });
}

function update_sortable($this) {
  $.post('pages/position', '_method=put&authenticity_token=' + encodeURIComponent(AUTH_TOKEN)+'&'+$this.sortable('serialize'));
}

function shrink($this) {
  $("."+$this.attr("id")).hide();
  $(".shrinker_"+$this.attr("id")).hide();
  $(".expander_"+$this.attr("id")).show();
  $(".sub_"+$this.attr("id")).hide();
  $(".sub_shrinker_"+$this.attr("id")).hide();
  $(".sub_expander_"+$this.attr("id")).show();
  $(".sub_sub_"+$this.attr("id")).hide();
  $(".sub_sub_shrinker_"+$this.attr("id")).hide();
  $(".sub_sub_expander_"+$this.attr("id")).show();
}

function expandPages(pageId, mainPageId, subPageId, subSubPageId){
  if (pageId != 0) {
    $(".page_"+pageId).show();
    $(".shrinker_page_" + pageId).show();
    $(".expander_page_" + pageId).hide();
  };
};
