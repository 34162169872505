$(function(){
  if($(".administration").length == 0 && $(".authentication").length == 0) {
    var navigation = function() {

      /* Elements */
      $document = $(document);
      $window = $(window);
      $header = $("#js-header");
      var lastY = $window.scrollTop(), lastDirection = "none"; 
      
      /* Initialise */
      HPA.resetTopButton();

      /* Event Handlers */
      $document
        .on("click", "#js-scroll-top", function(event) {
          event.preventDefault();
          $("html, body").animate({ scrollTop: 0 }, "slow");
          return false;
        })
        .on("click", ".navigation__menu__item--icon", function(event) {
          $(this).toggleClass("navigation__menu__item--active");
          return false;
        })
        .on("sticky.zf.unstuckfrom:bottom sticky.zf.unstuckfrom:bottom sticky.zf.stuckto:bottom sticky.zf.stuckto:top", function(event) {
          $(".scroller__button").css("opacity", 1);
        });

      $(".wrapper").on("mutateme.zf.trigger", function() {
        HPA.resetTopButton();
      });

      $window.on("scroll", bouncyHeader);

      /* Functions */  
      function bouncyHeader() {
        // get current scroll position
        var currentY = $window.scrollTop(),
       
        // determine current scroll direction
        currentDirection = (currentY > lastY) ? "down" : ((currentY === lastY) ? "none" : "up");

        // collapse the header if the user is scrolling down, beyond the height of the header and 
        // the out board is closed.
        if(currentDirection == "down" 
          && lastDirection != "down" 
          && currentY > $header.height() 
          && $(".out-board.is-open").length == 0) {
          $header.addClass("collapsed");
        }
        if(currentDirection == "up" && lastDirection != "up") {
          $header.removeClass("collapsed");
        }

        // update last scroll position to current position
        if(currentY > $header.height()) {
          lastDirection = currentDirection;
        }
        
        lastY = currentY;
      }
    }();
  }
});

HPA.resetTopButton = function(){
  $scrollerBumper = $("#js-header-bumper");
  $scroller = $("#js-sticky");
  $main = $("main");
  var topButtonOffset = 120;
  var windowHeight = $(window).height();
  var footerHeight = $("footer").height();
  var mainHeight = $main.outerHeight();
  var mainOffset = $main.offset().top;

  // resize the bumper that prevents the top button from going too high up the page
  if(mainHeight > (windowHeight + 25)) {
    $scrollerBumper.height(mainHeight - windowHeight + mainOffset - topButtonOffset + 25);
    // reposition the button if it has dropped below the footer line (foundation sticky bug)
    if($scroller.hasClass("sticky") && $scroller.offset().top > mainOffset) {
      $scroller.foundation("_calc", true);
    }
    $scrollerBumper.show();
  }
  else {
    $scrollerBumper.height(0);
    $scrollerBumper.hide();
  }
}