HPA.confirmStaffMemberIn = function(staff_member_name, staff_member_id) {
  if(confirm("Are you sure you want to set " + staff_member_name + " as in?")) {
    $.ajax({ 
      url: "/staff_availability",
      data: { id: staff_member_id },
      type: "DELETE",
      success: function(data) {
        setTimeout(function() {
          $("#staff-member-" + staff_member_id).fadeOut("slow").remove();
          $("#staff_member_id").append("<option value='" + staff_member_id + "'>" + staff_member_name + "</option>"); 
        }, 1000);
      }
    });
  }    
  else {
    $("#staff_member_" + staff_member_id + "_in_toggle").prop("checked", true)
  }
}

HPA.getOutBoardScrollHeight = function() {
  /* set the max height on the container so that it is scrollable and an 
     appropriate height to fit in the viewport */
  var scrollHeight = $(window).height() - 360 - $("header").height();
  /* there is no screen real estate - allow the mist to appear in full */
  if(scrollHeight < 50) {
    scrollHeight = 10000;
  }
  return scrollHeight;
}

$(document).on("click", ".out-board__switch__input", function(e) {
  staff_member_id = $(this).siblings(".member_id").val();
  staff_member_name = $(this).siblings(".member_name").val();
  HPA.confirmStaffMemberIn(staff_member_name, staff_member_id);
});

/* toggle the visibility of the out-board */
$(document)
  .on("on.zf.toggler", "#js-out-board", function(e) {
    $board = $(e.target);
    var scrollHeight = HPA.getOutBoardScrollHeight();
    $("#js-staff-scrolling-list").css("max-height", scrollHeight);
    $board.slideDown(500, function(){
      HPA.resetTopButton();
    });
  })
  .on("off.zf.toggler", function(e) {
    $(e.target).slideUp(500, function(){
      HPA.resetTopButton();
    });
  });

/* close out board if there is a click event outside the out board */
$(document)
  .on("click", function(e) {
    if(!$(e.target).closest(".out-board__container").length && $(".out-board.is-open").length) {
      $("a[data-toggle='js-out-board']").click();
      $("#staff_member_id").select2("close");
    }
  });

/* adjust the max hieght of the staff list if the window height is changed */
$(window).resize(function() {
  if($("#js-out-board").hasClass("is-open")) {
    var scrollHeight = HPA.getOutBoardScrollHeight();
    $("#js-staff-scrolling-list").css("max-height", scrollHeight);
  }
});

$(function(){
  $staffSelect = $("#staff_member_id").select2({
    placeholder: "Select your name",
    allowClear: true,
    theme: "hpa-dark"
  });
  $staffSelect.on("select2:select", function() {
    $(this).focus();
  });
});