$(function() {
  if ($(".staff-directory.show").length) {

    $searchSelect = $("#search-select").select2({
      placeholder: "Search staff by tag...",
      theme: "hpa-light",
      minimumResultsForSearch: Infinity,
      allowClear: true
    });

    $(".search-bar__cloud").on("click", ".search-bar__button--tag", function(e) {
      e.preventDefault();

      /* unselect a filter */
      if($(this).hasClass("active")) {
        HPA.clearSearchTagFilter();
        HPA.staffSearch();
      }
      /* select a new filter and clear existing ones */
      else {
        HPA.applySearchTagFilter($(this).data("tag"));
        HPA.staffSearch();
      }
    });

    $("#js-search-submit").click(function() {
      HPA.staffSearch();
    });

    $(document).on('keyup', "#search-input", function(event) {
      query = $("#search-input").val()
      if(query.length <= 3) {
        HPA.hideNoResultsFound();
      }
      HPA.staffSearch();
    });

    HPA.staffSearch = function() {
      var $this = $("#search-input");
      var keyword = $this.val().toLowerCase();
      var tag = $("#search-select option:selected").text().toLowerCase();

      if (keyword === "" && tag == "") {
        $('#js-search-results .staff-member-card').show();
        HPA.hideNoResultsFound();
      } else {
        $('#js-search-results .staff-member-card').each(function() {
          var text = $(this).text().toLowerCase();
          var cardTags = $(this).find(".staff-member-card__tags--hidden").text().toLowerCase().split(",");
          if (text.match(keyword) || keyword == "") {
            for(var i = 0; i < cardTags.length; i++) {
              var searchTag = $.trim(cardTags[i])
              if(searchTag === tag || tag == "") {
                $(this).show();
                break;
              } else {
                $(this).hide();
              }
            }
          } else {
            $(this).hide();
          }
        });
      }
      HPA.resetTopButton();
      HPA.highlight(keyword);
      HPA.showNoResultsFound();
    };

    $("#js-search-clear").click(function() {
      HPA.clearSearchTagFilter();
      HPA.clearSearchKeyword();
      HPA.staffSearch();
    });

     /* selection on category dropdown */
    $searchSelect.on("select2:select", function() {
      $(this).focus();
      HPA.applySearchTagFilter($(this).val());
      HPA.staffSearch();
    });

    /* clearing category selection on dropdown  */
    $searchSelect.on("select2:unselect", function() {
      HPA.clearSearchTagFilter();
      HPA.staffSearch();
    });
  }
});
