$(function(){
  $(document)
    .on("on.zf.toggler", "#js-mobile-menu", function(e) {
      $board = $(e.target);
      $board.slideDown(500, function(){
        HPA.resetTopButton();
      });
    })
    .on("off.zf.toggler", function(e) {
      $(e.target).slideUp(500, function(){
        HPA.resetTopButton();
      });
  });
});