window.NewsSearchView = (function () {
  var $page = null;
  var $searchSelect = null;
  var $searchInput = null;
  var $searchContainer = null;
  var $searchSubmit = null;
  var $tagCloud = null;
  var $searchClear = null;

  var init = function($el) {
    // Do nothing if the element we were passed does not exist. This makes this
    // init() function safe to call on all pages.
    if ($el.length == 0) { return; }
    $page = $el;

    initialiseControls();
    setupEventListeners();
    HPA.highlight($searchInput.val());
    newsAutoRefresh();
  };

  var initialiseControls = function() {
    $searchSelect = $("#search-select");
    $searchInput = $("#search-input");
    $searchContainer = $("#js-search-results");
    $searchSubmit = $("#js-search-submit");
    $tagCloud = $(".search-bar__cloud");
    $searchClear = $("#js-search-clear");

    $searchSelect.select2({
      placeholder: "Search news by category...",
      theme: "hpa-light",
      minimumResultsForSearch: Infinity,
      allowClear: true
    });
  }

  var setupEventListeners = function() {
    $searchSubmit.click(searchNewsItems);
    $searchClear.click(clearSearchForm);
    $searchInput.on("keyup", searchWhileTyping);
    $tagCloud.on("click", ".search-bar__button--tag", tagCategoryChanged);
    $searchSelect.on("select2:select", selectCategorySelected);
    $searchSelect.on("select2:unselect", selectCategoryUnselected);
    $page.on("click", "#js-pagination a", nextPage);
  };

  var clearSearchForm = function() {
    HPA.clearSearchTagFilter();
    HPA.clearSearchKeyword();
    searchNewsItems();
    $searchContainer.removeClass("searching");
  };

  var searchNewsItems = function() {
    //Clear the current news items
    $searchContainer.empty();
    $searchContainer.css("opacity", 0)
    $searchContainer.addClass("searching");
    var keyword = $searchInput.val();
    var category = $searchSelect.val();
    
    //Perform search
    $.get("/", { q: keyword, t: category }, function() {
      HPA.resetTopButton();
      HPA.highlight(keyword);
      //Ensure the page can be reloaded or returned to
      pushSearchState(keyword, category, 1);
      if ($searchContainer.children().length) {
        $searchContainer.css("opacity", 1);
        HPA.hideNoResultsFound();
      } else {
        HPA.showNoResultsFound();
      }
    }, "script");
  };

  var searchWhileTyping = function() {
    query = $(this).val();
    if(query.length >= 3 || query.length == 0) {
      HPA.delay(function(){
        searchNewsItems();
      }, 250 );
    }
  };

  var tagCategoryChanged = function(e) {
    e.preventDefault();

    /* unselect a filter */
    if($(this).hasClass("active")) {
      HPA.clearSearchTagFilter();
      searchNewsItems();
    }
    /* select a new filter and clear existing ones */
    else {
      HPA.applySearchTagFilter($(this).data("tag"));
      searchNewsItems();
    }
  };

  var selectCategorySelected = function() {
    $(this).focus();
    HPA.applySearchTagFilter($(this).val());
    searchNewsItems();
  };

  var selectCategoryUnselected = function() {
    HPA.clearSearchTagFilter();
    searchNewsItems();
  };

  var nextPage = function(e) {
    e.preventDefault();
    $.getScript(this.href, function() {
      var keyword = HPA.getUrlParameter(this.url, "q");
      HPA.highlight(keyword);
      HPA.resetTopButton();
    });
    history.pushState(null, window.title, this.href);
    return false;
  }

  var newsAutoRefresh = function() {
    var interval = 20000;
    setInterval(function() {
      var keyword = $searchInput.val();
      var category = $searchSelect.val();
      var page = HPA.getUrlParameter(window.location, "page");
      $.get("/news/refresh", { q: keyword, t: category, page: page }, function() { HPA.highlight(keyword) }, "script");
    }, interval);
  };

  var pushSearchState = function(keyword, category, page) {
    var page = page || HPA.getUrlParameter(this.url, "page") || 1
    var url = "?q=" + encodeURIComponent(keyword) + "&t=" + encodeURIComponent(category) + "&page=" + page;
    if (history.pushState) history.pushState(null, null, url)
  };

  return {
    init: init
  };
}());