$(function() {
  $('#js-session-duration').timeDurationPicker({
    defaultValue: function() {
      return $('#js-session-seconds').val();
    },
    onSelect: function(element, seconds, duration) {
      $('#js-session-seconds').val(seconds);
      $('#js-session-duration').val(duration);
    }
  });
});